import PropTypes from 'prop-types';
import styles from './Accordion.module.scss';
import React, { useState, useRef, useEffect } from 'react';
import NextImage from 'next/image';
import classNames from 'classnames';
import RawHtml from '../RawHtml/RawHtml';

const Accordion = ({ title, text, images, richText, alwaysOpen, onLinkClick }) => {
    const [isOpen, setIsOpen] = useState(alwaysOpen);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    const slug = title?.toLowerCase().replace(/\s+/g, '-');

    const contentClasses = classNames(styles['Accordion__Content'], {
        [styles['Accordion__Content--Active']]: isOpen === slug,
        [styles['Accordion__Content--Transition']]: isOpen === slug,
    });

    const iconClasses = classNames(styles['Accordion__Icon'], {
        [styles['Accordion__Icon--Active']]: isOpen === slug,
    });

    useEffect(() => {
        if ((isOpen === slug)) {
            setContentHeight(contentRef.current.scrollHeight);
        } else {
            setContentHeight(0);
        }
    }, [isOpen, slug]);

    const toggleAccordion = (slug) => {
        setIsOpen((prevState) => (prevState === slug ? null : slug));
    };

    
    return (
        <div className={classNames(styles['Accordion'], {
            [styles['Accordion--AlwaysOpen']]: alwaysOpen,
        })}>
            <div className={styles['Accordion__Container']}>
                <div className={styles['Accordion__Wrapper']}>
                    <h2 className={styles['Accordion__Title']}>{title}</h2>
                    <div className={styles['Accordion__Text']}>
                        <RawHtml html={text} onLinkClick={onLinkClick}/>
                    </div>
                    {!alwaysOpen && (
                        <button
                            onClick={() => toggleAccordion(slug)}
                            aria-controls={slug}
                            aria-expanded={isOpen === slug ? 'true' : 'false'}
                            className={styles['Accordion__Button']}>
                            <span className={iconClasses}></span>
                        </button>
                    )}
                </div>

                <div
                    className={contentClasses}
                    style={{ maxHeight: alwaysOpen ? "100%" : `${contentHeight}px` }}
                    ref={contentRef}
                    id={slug}
                >
                    <div className={styles['Accordion__Images']}>
                        {images?.map((item, index) => (
                            <NextImage
                                key={index}
                                src={item.image.url}
                                priority={true}
                                width={0}
                                height={0}
                                sizes="100vw"
                                style={{ width: '100%', height: 'auto' }}
                                className={styles['Accordion__Image']}
                            />
                        ))}
                    </div>

                    <div className={styles['Accordion__RichText']}>
                        <RawHtml html={richText} onLinkClick={onLinkClick}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired,
            }).isRequired,
        })
    ).isRequired,
    richText: PropTypes.string.isRequired,
    alwaysOpen: PropTypes.bool,
};

export default Accordion;
