import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './RawHtml.module.scss';

const RawHtml = ({ html, onLinkClick }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!onLinkClick) return;  

    const handleClick = (event) => {
      if (event.target.tagName === 'A') {
        onLinkClick(event);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('click', handleClick);
    }

    return () => {
      if (container) {
        container.removeEventListener('click', handleClick);
      }
    };
  }, [onLinkClick]);

  return (
    <div
      ref={containerRef}
      className={styles['RawHtml']}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

RawHtml.propTypes = {
  html: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func,
};

RawHtml.defaultProps = {
  html: '',
  onLinkClick: null,
};

export default RawHtml;
